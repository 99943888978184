const token = 'user-token'

// 获取token
export const getToken = () => {
  return localStorage.getItem(token)
}

// 存储token
export const setToken = newToken => {
  localStorage.setItem(token,newToken)
}

// 封装移除本地存储中的 token 的方法
export const removeToken = () => {
  localStorage.removeItem(token)
}