<template>
  <div class="page">
    <div class="diamond-treasure"></div>
    <!-- 动态数据 -->
    <div class="treasure">
      <div
        class="rectange"
        v-for="item in list"
        :key="item.id"
        @click="homeSnatch(item.id)"
      >
        <div class="left">
          <div class="top">
            <div class="status" v-show="item.open_state == 0">
              <span class="text">距开抢</span>
            </div>
            <div class="status_1" v-show="item.open_state == 1">
              <span class="text">开奖中</span>
            </div>
            <div class="status_2" v-show="item.open_state == 2">
              <span class="text">已结束</span>
            </div>
            <div class="count" v-show="item.open_state == 0">
              <span class="text_1">差{{ item.remain }}份</span>
            </div>
          </div>
          <div class="bottom">
            <img :src="item.reward_icon" alt="" />
          </div>
        </div>
        <div class="right">
          <div class="right_top">
            <span>{{ item.title }}</span>
          </div>
          <div class="right_middle">
            <div class="progress-bar">
              <span
                class="progress"
                :style="{ width: item.join_ratio + '%' }"
              ></span>
            </div>
          </div>
          <div class="right_bottom">
            <div class="percentage">
              <span>{{ item.join_ratio }}%</span>
            </div>
            <div class="joinNow">
              <div class="background">
                <span>立即参与</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rectange-transparent"></div>
    </div>
    <!-- 排行榜 -->
    <div class="ranking" @click="homeRanking"></div>
    <!-- 底部tabbar -->
    <!-- <van-tabbar class="tabbarEl" v-model="active" route>
      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o" to="/mine">我的</van-tabbar-item>
    </van-tabbar> -->
    <!-- 底部原生tabbar -->
    <div class="tabbarOrigin">
      <div class="left">
        <van-icon name="wap-home-o" color="#1989fa" />
        <div class="text">首页</div>
      </div>
      <div class="right" @click="mine">
        <van-icon name="user-o" />
        <div class="text">我的</div>
      </div>
    </div>
    <!-- 二版 -->
    <!-- <div class="tabbarOrigin">
      <div class="left"> 
        <img src="./assets/img/home_icon.png" alt="">       
        <div class="text">首页</div>
      </div>
      <div class="right" @click="mine">
        <img src="./assets/img/icon_mine.png" alt="">
        <div class="text">我的</div>
      </div>
    </div> -->

    <!-- 弹窗测试 -->
    <template v-if="false">
      <div class="tsetPoop" v-show="showPop" @click="showPop = false">
        <div>{{ windowLocalHref }}</div>
        <div>上页</div>
      </div>
      <van-button @click="showPop = true">测试弹窗</van-button>
    </template>
  </div>
</template>
<script>
import { getHomePageInfo } from "@/api/treasure.js";
import { setToken, getToken, removeToken } from "@/utils/localStorage.js";
export default {
  data() {
    return {
      constants: {},
      // 进度条百分比
      progress: 70,
      active: 0,
      // 渲染列表
      list: [],
      // tabbar切换
      isActiveIcon2: false,
      //
      windowLocalHref: "",
      //
      showPop: false,
    };
  },
  methods: {
    homeSnatch(id) {
      console.log(id);
      this.$router.push({
        path: "/homeSnatch",
        query: {
          id: id,
        },
      });
    },
    homeRanking() {
      this.$router.push("/homeRanking");
    },
    mine() {
      this.$router.push("/mine");
    },
  },
  async created() {
    const res = await getHomePageInfo();
    this.list = res.data;
    console.log(window.location.href);
  },
};
</script>
<style scoped lang="less" src="./assets/index.rem.less">
</style>
<style scoped>
.countDownEl {
  font-size: 0.56rem;
  color: red;
  margin-left: 0.2rem;
}
/* ::v-deep .tabbarEl {
  height: 2rem;
} */
/* .van-tabbar--fixed {
  position: absolute;
  bottom: 0;
} */
</style>