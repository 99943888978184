/** @format */

import { setToken } from '@/utils/localStorage.js'

export const setTokenUrl = () => {
  // let testUrl = 'http://lottery.miaokuizhibo.com/?user-token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJrbCIsImlhdCI6MTY4MDE1MzYyMiwibmJmIjoxNjgwMTUzNjI1LCJleHAiOjE2ODA3NTg0MjIsImRhdGEiOnsidXNlcl9pZCI6MTE2NDAxLCJpc19yZWZyZXNoIjowfX0.LFdV-TC6nVPiTxstBrNkzMkBq2qG9Zu1KLPCxOUnNm0'
  let testUrl = window.location.href

  let indexNum = 0;
  let addNum = 0
  if (testUrl.indexOf('user-token') >= 0) {
    indexNum = testUrl.indexOf('user-token')
    addNum = 10
  } else {
    indexNum = testUrl.indexOf('token')
    addNum = 5
  }
  let tokenInfo = testUrl.slice(indexNum + 1 + addNum)
  let tokenInfo1 = tokenInfo.split('&')
  let tokenInfo2 = decodeURIComponent(tokenInfo1[0] ?? '')
  setToken(tokenInfo2)
}
