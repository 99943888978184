var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('van-nav-bar',{staticClass:"navBarEl",attrs:{"title":"开奖规则","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"ruleText"},[(false)?[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]:_vm._e(),_c('div',{staticClass:"div",domProps:{"innerHTML":_vm._s(_vm.postcessRichtext)}})],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("1.玩家每"),_c('span',{staticClass:"blueText"},[_vm._v("购买一份，将随机分配"),_c('span',{staticClass:"blueText"},[_vm._v("一个号码")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("2.当累计购买"),_c('span',{staticClass:"blueText"},[_vm._v("份额满时")]),_c('span',[_vm._v("，系统自动开奖")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("3.为体现每次夺宝绝对的公平性不可操控性，当份额满足后，将延迟一分钟，取")]),_c('span',{staticClass:"blueText"},[_vm._v("第三方平台")]),_c('span',[_vm._v("对应时间点随机数作为随机数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',{staticClass:"blueText"},[_vm._v("幸运号码规则：(本期所有购买记录时间相加 + 第三方平台随机数) % 份数 + 10000001 注意：购买记录时间按照时、分、秒、毫秒排列，QQ实时在线人数(延迟1分钟数值),")]),_c('span',[_vm._v("可以点击查看，")]),_c('span',{staticClass:"blueText"},[_vm._v("规则中 % 符号是取余数计算")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("4.由于需要等待第三方平台随机数数据，所以开奖会有3分钟延迟 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("5.不同的类型夺宝总份数和单份价格也会有所不同，并且会限制单个用户参与数量 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("6.参与夺宝后等待期间，即使临时有事无法登陆软件也不影响获奖资格")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('span',[_vm._v("7.考拉live 反对未成年人参与相关玩法，同时请参与夺宝的用户务必注意健康娱乐，适度消费 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v(" 8.注：请知悉考拉live平台禁止并严厉打击任何形式的线下交易，通过夺宝获得的奖励不可以兑换现金，谨防上当受骗，平台不保障所有通过线下交易销售或购买的钻石，礼物，道具等。考拉live有权终止向违规账号提供服务，违规账户中的虚拟产品被清空。由此造成的损失由用户自行承担！")])])
}]

export { render, staticRenderFns }