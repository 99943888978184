import Vue from 'vue'

Vue.directive('touch', {
  // bind: function (el, binding) {
  //   var startX, startY
  //   el.addEventListener(
  //     'touchstart',
  //     function (event) {
  //       startX = event.touches[0].pageX
  //       startY = event.touches[0].pageY
  //     },
  //     false
  //   )
  //   el.addEventListener(
  //     'touchmove',
  //     function (event) {
  //       var deltaX = event.touches[0].pageX - startX
  //       var deltaY = event.touches[0].pageY - startY
  //       if (deltaY < 0) {
  //         binding.value()
  //       }
  //     },
  //     false
  //   )
  // },
})