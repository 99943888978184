/** @format */

import axios from 'axios'
import { getToken,removeToken } from './localStorage'

const request = axios.create({
  // baseURL: 'http://apiv2.miaokuizhibo.com/api/h5/lottery/',
  // 正式服域名
  baseURL: 'https://api2.koalalive.com/api/h5/lottery/',
  timeout: 50000,
})
// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    const token = getToken()
    // const token = this.$store.state.token
    // 添加请求头
    if (token) {
    config.headers['user-token'] = token
    }

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 解套处理
    // console.log('response', response)
    return response.data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default request
