import Vue from "vue";
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    token: '',
    richText: ''
  },
  mutations: {
    setTokenStore(state,newToken) {
      state.token = newToken
    },
    setRichText(state,newRichText) {
      state.richText = newRichText
    }
  },
})
export default store